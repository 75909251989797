import "./Contact.scss"

const Contact = () => {
    return(
        <div className="contact">
            <div className="box">
                <div className="link contact">Contact</div>
                <a className="link" href="mailto:kevinson95@gmail.com">Email</a>
                <a className="link" href='https://github.com/CodingKevin95' target="_blank" rel="noreferrer">GitHub</a>
                <a className="link" href='https://www.linkedin.com/in/kevins-on/' target="_blank" rel="noreferrer">LinkedIn</a>
                <a className="link" href='https://content.celero.io/s/c04d7eaa/kevin-son-resumedocx/?page=1' target="_blank" rel="noreferrer">Resume</a>
            </div>
        </div>
    )
}

export default Contact