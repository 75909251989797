import { useState } from "react"
import "./ClientPortfolio.scss"
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';

const ClientPortfolio = () => {

    const [main, setMain] = useState('0')

    console.log(main)

    const data = [
        {
            id:1,
            title: "Aer Nailbar",
            img: "./aer-photo.png",
            desc: "Created a website for client's business which recieves 1000's of visits per week. The frontend was made usings CSS, React.js and some react libraries. For the backend, a CMS from sanity.io was used for the client to update their servies and information. Google analytics was implimented to help the client create a business plan to increase the proformance of the business.",
            git: "https://github.com/CodingKevin95/client-Aer",
            liveLink: "https://aernailbar.com/"
        },
        {
            id:2,
            title: "Adorem Bridal",
            img:  "./Adorem-bridal.png",
            desc: "Still in the works. Curently consulting and building an ecommerce bridal website for a client. This site will allow customers to browse through a portfolio of bridal dresses and accessories and give the option to buy or rent certain pieces. The frontend of the application is developed with React.Js and scss. While the backend uses SQL with CMS technology Stripi.io to perform CRUD actions for the client and customer side. Lastly Stripe and their API was implemented for the client's business payment method.",
            vid: "./Adorem-bridal.mov"
        },
    ]

    console.log(data[main].vid)

    return(
        <div className="clientPortfolio">
            <div className="box">
                <div className="title">Client Projects</div>
                <div className="clientProjectSlider">
                    <img className='clientProjectPhoto' src={require(`${data[0].img}`)} alt="" onClick={e=>setMain(0)} />
                    <img className='clientProjectPhoto' src={require(`${data[1].img}`)} alt="" onClick={e=>setMain(1)} />
                </div>
                <div className="clientPortfolioBottom">
                    <div className="right">
                        {data[main].vid ?
                        <video className='mainClientPhoto' autoPlay muted loop>
                            <source src={require(`${data[main].vid}`)} alt="" />
                        </video>
                        :
                        <img className='mainClientPhoto' src={require(`${data[main].img}`)} alt="" />}
                    </div>
                    <div className="left">
                        <div className="clientProjectTitle">
                        {data[main].title}
                            <div className="icons">
                                <a href={data[main].git} target="_blank" rel="noreferrer"><GitHubIcon className="iconsButton"/></a>
                                <a href={data[main].liveLink} target="_blank" rel="noreferrer"><LinkIcon className="iconsButton"/></a>
                            </div>
                        </div>
                        <div className="clientProjectDesc">
                            {data[main].desc}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientPortfolio