import { useState } from "react"
import "./ClientPortfolio.scss"
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';

const PersonalProjects = () =>{

    const [main, setMain] = useState('0')

    console.log(main)

    const data = [
        {
            id:1,
            title: "Rock Paper Scissor",
            img: "./RPS.jpg",
            desc: "Desktop and laptop view only. A classic game that everyone knows and loves, but with a short narrative, an unique UI and interactions to give it a little bit of life. Hope you enjoy!",
            vid: "./RPS-video.mov",
            git: "https://github.com/CodingKevin95/-rock-paper-scissor",
            liveLink: "https://codingkevin95.github.io/-rock-paper-scissor/"
        },
        {
            id:2,
            title: "Solar Conveyance",
            img:  "./space.png",
            desc: "The objectivce of this application is to display images and information about our solar system. APIs from SpaceX and NASA were used to create this application.",
            git: "https://github.com/CodingKevin95/solar-conveyance",
            liveLink: "https://solar-conveyance-project.netlify.app/"
        },
        {
            id:3,
            title: "Weather App",
            img:  "./weather-app.png",
            desc: "This application allows the user to search their desired location's current weather, hourly forcast, daily forcast, and other minor descriptions. This is all done by using OpenWeather's API.",
            git: "https://github.com/CodingKevin95/React-Weather-Project",
            liveLink: "https://weather-app-2a1a4b.netlify.app/"
        },
    ]

    return(
        <div className="clientPortfolio">
            <div className="box">
                <div className="title">Personal Projects</div>
                <div className="clientProjectSlider">
                    <img className='clientProjectPhoto' src={require(`${data[0].img}`)} alt="" onClick={e=>setMain(0)} />
                    <img className='clientProjectPhoto' src={require(`${data[1].img}`)} alt="" onClick={e=>setMain(1)} />
                    <img className='clientProjectPhoto' src={require(`${data[2].img}`)} alt="" onClick={e=>setMain(2)} />
                    {/* <img className='clientProjectPhoto' src={require(`${data[2].img}`)} alt="" onClick={e=>setMain(3)} /> */}
                </div>
                <div className="clientPortfolioBottom">
                    <div className="right">
                        {data[main].vid ?
                        <video className='mainClientPhoto' autoPlay muted loop>
                            <source src={require(`${data[main].vid}`)} alt="" />
                        </video>
                        :
                        <img className='mainClientPhoto' src={require(`${data[main].img}`)} alt="" />}
                    </div>
                    <div className="left">
                        <div className="clientProjectTitle">
                        {data[main].title}
                        <div className="icons">
                                <a href={data[main].git} target="_blank" rel="noreferrer"><GitHubIcon className="iconsButton"/></a>
                                <a href={data[main].liveLink} target="_blank" rel="noreferrer"><LinkIcon className="iconsButton"/></a>
                            </div>
                        </div>
                        <div className="clientProjectDesc">
                            {data[main].desc}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalProjects