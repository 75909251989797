import { useEffect } from "react";
import SVG from "../SVG/SVG"
import "./Home.scss"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Home = () => {

    const scrollToSection = () => {
        window.scrollTo({
            top: document.body.offsetHeight,
            behavior: "smooth"
        })
    };

    return(
        <div className="home">
            <div className="box">
                <p className="info">
                    Hi, my name is <span className="name">Kevin</span>. A <span className="highlight">React.JS</span> developer with experience in building client and server-side web applications.
                </p>
                <div className="infoWithIcon">
                    <p className="info middle">
                        Though I mainly work with <span className="highlight">React ecosystem</span>, I'm always actively learning new tools and technology. With <span className="highlight">Frontend</span>, I have experience using <span className="highlight">JavaScript</span> and <span className="highlight">React.JS</span>
                    </p>
                    <div className="contactMe">
                        <SVG />
                    </div>
                </div>
                <p className="info">
                        While on the Backend, I have experience with <span className="highlight">NodeJS</span>, <span className="highlight">Express</span>, <span className="highlight">MongoDB</span>, <span className="highlight">SQL</span> and <span className="highlight">CMS</span>.
                </p>
                <div className="info"><span className="highlight connect" onClick={scrollToSection}>Let's connect <ArrowForwardIcon className="arrow"/> </span></div>
            </div>
        </div>
    )
}

export default Home