import Home from "./pages/Home/Home";
import ClientPortfolio from "./pages/Portfolio/ClientPortfolio";
import "./App.scss"
import SVG from "./pages/SVG/SVG";
import PersonalProjects from "./pages/Portfolio/PersonalProjects";
import Contact from "./pages/Contact/Contact";

function App() {
  return (
    <div className="app">
      <Home />
      <ClientPortfolio />
      <PersonalProjects />
      <Contact />
    </div>
  );
}

export default App;
